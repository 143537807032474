import React from 'react';
import { Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const ListingCard = (props) => {
  return (
    <Link to={`/profile/${props.hit.objectID}`}>
      <Card
        hoverable
      >
      <Meta
        title={props.hit.name}
        avatar={<Avatar size="large" src={props.hit.photoURL} />}
      />
      </Card>
    </Link>
  )
}

export default ListingCard;
