import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../actions/UserActions.js';
import { getChats } from '../actions/ChatActions';
import ChatList from '../components/ChatList.js';

class Chats extends Component {

  constructor(props){
    super(props)

    this.state = {
      loaded: false,
      user: {}
    }
  }

  componentWillMount(){
    this.props.getChats(this.props.user.uid).then(() => {
      this.setState({
        loaded: true
      })
    })
  }

  render(){
    return (
      <div>
        {this.state.loaded
          ?
            <ChatList user={this.props.user.uid} chats={this.props.chats} />
          :
            <div>Loading Messages …</div>
        }
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
  chats: state.chats
}), { getChats, getUser })(Chats);
