import React from 'react';
import { Button, Popconfirm } from 'antd';

const DeleteAccountButton = (props) => {

  const handleDelete = e => {
    props.deleteUser(props.user.uid);
  }

  return (
    <Popconfirm title="Are you sure delete this task?" onConfirm={handleDelete} okText="Delete Account" cancelText="Nevermind">
      <Button size='large' type='danger' ghost>Delete Account</Button>
    </Popconfirm>
  )
}

export default DeleteAccountButton;
