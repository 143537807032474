import React from 'react';
import { Card, Avatar } from 'antd';
import moment from 'moment';
const { Meta } = Card;

const ReservationCard = (props) => {

  const startDate = moment.unix(props.reservation.startDate.seconds).format('LL');
  const endDate = moment.unix(props.reservation.endDate.seconds).format('LL');

  const amount = (props.reservation.listing.amount / 100);
  const payout = (amount / 1.15).toFixed(2);

  return (
    <Card
      style={{ marginBottom: '2rem' }}
      cover={<img alt="example" src={props.reservation.listing.image} />}
      >
    <Meta
      avatar={<Avatar src={props.reservation.requester.photoURL} />}
      title={props.reservation.listing.title}
      description={`from ${startDate} to ${endDate} - $${payout}`}
    />
  </Card>
  )
}

export default ReservationCard;
