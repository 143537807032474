import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import Home from './Home';
import Search from './Search';
import Profile from './Profile';
import Listing from './Listing';
import Chats from './Chats';
import AddListing from './AddListing';
import EditListing from './EditListing';
import Message from './Message';
import Requests from './Requests';
import PaymentSettings from './PaymentSettings';
import RequestSuccess from './RequestSuccess';
import Trips from './Trips';
import Terms from './Terms';
import Reservations from './Reservations';
import About from './About';
import DeleteSuccess from './DeleteSuccess';
import Host from './Host';
import ListingSuccess from './ListingSuccess';
import UpdateListingSuccess from './UpdateListingSuccess';
import EditProfile from './EditProfile';
import Register from './Register';
import Login from './Login';
import ReferenceRequest from './ReferenceRequest';
import AddReference from './AddReference';

import styled from 'styled-components'

const Wrapper = styled.main`
  width: 30vw;
  min-width: 300px;
`;

const Main = (props) => {
  return (
    <Wrapper>
      <Switch>
        <ProtectedRoute user={props.user} exact path='/' component={Home} />
        <ProtectedRoute user={props.user} path='/search' component={Search} />
        <ProtectedRoute user={props.user} path='/add-listing/' component={AddListing} />
        <ProtectedRoute user={props.user} path='/profile/:id' component={Profile} />
        <ProtectedRoute user={props.user} path='/listing/:id' component={Listing} />
        <ProtectedRoute user={props.user} path='/requests' component={Requests} />
        <ProtectedRoute user={props.user} path='/edit/listing/:id' component={EditListing} />
        <ProtectedRoute user={props.user} path='/trips' component={Trips} />
        <ProtectedRoute user={props.user} exact path='/messages' component={Chats} />
        <ProtectedRoute user={props.user} path='/messages/:id' component={Message} />
        <ProtectedRoute user={props.user} path='/request-success' component={RequestSuccess} />
        <ProtectedRoute user={props.user} path='/reservations' component={Reservations} />
        <ProtectedRoute user={props.user} path='/payment-settings' component={PaymentSettings} />
        <Route path='/about-us' component={About} />
        <ProtectedRoute user={props.user} path='/delete-success' components={DeleteSuccess} />
        <Route path='/terms' component={Terms} />
        <Route path='/login' component={Login} />
        <ProtectedRoute user={props.user} path='/add-reference/:uid' component={AddReference} />
        <ProtectedRoute user={props.user} path='/edit-profile' component={EditProfile} />
        <ProtectedRoute user={props.user} path='/host' component={Host} />
        <ProtectedRoute user={props.user} path='/register' component={Register} />
        <ProtectedRoute user={props.user} path='/update-success' component={UpdateListingSuccess} />
        <ProtectedRoute user={props.user} path='/listing-success' component={ListingSuccess} />
        <ProtectedRoute user={props.user} path='/request-reference' component={ReferenceRequest} />
      </Switch>
    </Wrapper>
  )
}

export default Main
