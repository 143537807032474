import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { logout } from '../actions/UserActions';
import { getUserRequests } from '../actions/RequestActions';
import styled from 'styled-components';
import RequestCount from './RequestCount';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  a {
    padding: 1rem;
    display: block;
    border: 1px solid rgba(0,0,0,0.12);
    margin-bottom: 1rem;
    height: auto !important;
  }
`;

const StyledLink = styled(Link)`
  padding: 0;
  display: block;
  border-radius: 4px;
  height: 32px;
`;

class HostNav extends Component {

  componentWillMount(){
    this.props.getUserRequests(this.props.user);
  }

  render(){

    let requestCount = Object.keys(this.props.requests).length;

    return (
      <nav>
        <List>
          <ListItem><StyledLink to={`/profile/${this.props.user.uid}`}><Icon type="bars" style={{ marginRight: '1rem' }} />Add a Space or Edit Listing</StyledLink></ListItem>
          <ListItem><StyledLink to='/messages'><Icon type="message" style={{ marginRight: '1rem' }} />Messages</StyledLink></ListItem>
          <ListItem><StyledLink to='/requests'><Icon type="check" style={{ marginRight: '1rem' }} /> <RequestCount requestCount={requestCount} /></StyledLink></ListItem>
          <ListItem><StyledLink to='/reservations'><Icon type="team" style={{ marginRight: '1rem' }} />Reservations</StyledLink></ListItem>
          <ListItem><StyledLink to='/payment-settings'><Icon type="credit-card" style={{ marginRight: '1rem' }} />Payment Settings</StyledLink></ListItem>
        </List>
      </nav>
    )
  }
}

export default connect(state => ({
  user: state.user,
  requests: state.requests
}), { logout, getUserRequests })(HostNav);
