import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { TextField } from 'redux-form-antd';
import { getCurrentUser, updateBankInfo } from '../actions/UserActions';

class BankInput extends Component{

  success = () => {
    message.success('Bank information updated');
  };

  error = () => {
    message.error('There was an error')
  }


  onSubmit = (values) => {
    this.props.updateBankInfo(this.props.user.uid, values).then((res) => {
      this.success()
    }).then(() => {
      this.props.getCurrentUser()
    })
  }

  render(){

    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <p>Add bank information</p>
        <Field
          name="routing"
          component={TextField}
          placeholder="Routing Number"
          size="large"
        />
        <Field
          name="account"
          component={TextField}
          placeholder="Account number"
          size="large"
        />
        <Button icon='dollar' size='large' type="primary" htmlType="submit">Update Bank Info</Button>
      </form>
    )
  }
}

let form = reduxForm({
  form: 'BankInput',
  enableReinitialize : true
})(BankInput);

form = connect(state => ({
  user: state.user,
}), { updateBankInfo, getCurrentUser })(form);

export default form;
