import React, { Component } from 'react';
import Header from './components/Header.js';
import Main from './containers/Main';
import Footer from './components/Footer.js';
import './App.css';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getCurrentUser } from './actions/UserActions';
import { getListings } from './actions/ListingActions';
import { withRouter } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Login from './containers/Login';

const Wrapper = styled.div`
  width: 40vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 320px;
`;

class App extends Component {

  componentWillMount(){
    this.props.getCurrentUser();
    this.props.getListings();
  }

  render() {
    return (
      <Wrapper>
        <Header />
        {this.props.user.loading
          ?
            <Login />
          :
            <Main user={this.props.user} />
        }
        <Footer />
      </Wrapper>
    );
  }
}

export default withRouter(connect((state, ownProps) => ({
  user: state.user,
  listings: state.listings,
}), {getCurrentUser, getListings})(App));
