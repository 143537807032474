import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTrips } from '../actions/TripActions';
import TripCard from '../components/TripCard';
import PageTitle from '../components/PageTitle';

class Trips extends Component {

  componentWillMount(){
    this.props.fetchTrips(this.props.user);
  }

  renderTrips = () => {
    return Object.keys(this.props.trips).map((trip, key)=>{

      const { trips } = this.props;
      const today = Date.now() / 1000;
      let ratingOpen = false;

      if(trips[trip].endDate.seconds < today) {

        ratingOpen = true;
      } else {
        ratingOpen = false;
      }

      return (
        <TripCard
          key={key}
          id={trip}
          ratingOpen={ratingOpen}
          trip={trips[trip]}
        />
      )
    })
  }

  render(){

    return (
      <div>
        <PageTitle>My Trips</PageTitle>
        {this.renderTrips()}
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
  trips: state.trips
}), { fetchTrips })(Trips);
