import React, { Component } from 'react';
import Nav from '../components/Nav';
import { Helmet } from 'react-helmet';

export default class Home extends Component {
  render(){
    return (
      <div>
        <Helmet title='Home — Kalitx' />
        <Nav />
      </div>
    )
  }
}
