import React, { Component } from 'react';
import {connect} from 'react-redux';
import { fetchCards, addCard, removeCard } from '../actions/CardActions';
import Checkout from '../components/Checkout';
import CardList from '../components/CardList';
import BankInput from '../components/BankInput';
import { Divider } from 'antd';
import { Helmet } from 'react-helmet';

class PaymentSettings extends Component {


  render(){
    return (
      <div>
        <Helmet title='Payment Settings — Kalitx' />
        <Checkout
          user={this.props.user}
          addCard={this.props.addCard}
          fetchCards={this.props.fetchCards}
        />
        <Divider/>
        <CardList
          user={this.props.user}
          cards={this.props.cards}
          fetchCards={this.props.fetchCards}
          removeCard={this.props.removeCard}
        />
        <Divider/>
        <BankInput
          initialValues={this.props.user.bankInfo}
        />
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
  cards: state.cards
}), { fetchCards, addCard, removeCard })(PaymentSettings);
