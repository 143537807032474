import React, { Component } from 'react';
import { getUserRatings } from '../actions/UserActions';
import { Avatar, Button, Divider, Rate } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Number = styled.div`
  font-size: 18px;
`;

class OverallRating extends Component {

  state = {
    isLoaded: false,
    ratings: []
  }

  componentDidUpdate(prevProps){
    if(prevProps.profileUser !== this.props.profileUser){
      this.props.getUserRatings(this.props.profileUser)
      .then(ratings => {
        this.setState({ ratings: ratings, isLoaded: true })
      })
    }
  }

  calculateRating = () => {
    const { ratings } = this.state;
    let numOfRatings = Object.keys(ratings).length;
    console.log(numOfRatings)
    let sum = 0;

    Object.keys(ratings).map((rating, key) => {
      sum = sum + ratings[rating].rating;
      return sum;
    });

    return sum / numOfRatings;
  }

  render() {

    const { ratings, isLoaded } = this.state;

    return (
      <>
        {isLoaded
          ?
            Object.keys(ratings).length > 0
              ?
                  <>
                    <Number><strong>Rating: {this.calculateRating()}</strong></Number>
                    <Divider />
                  </>
              :
                null
          :
            null
        }
      </>
    );
  }

}

export default connect(state => ({
  user: state.user,
}), { getUserRatings })(OverallRating);
