import React, { Component } from 'react';
import HostNav from '../components/HostNav.js';
import { Helmet } from 'react-helmet';

export default class Home extends Component {
  render(){
    return (
      <div>
        <Helmet title='Hosting Dashboard — Kalitx' />
        <HostNav />
      </div>
    )
  }
}
