const setDefaultFileList = (files) => {

  let arr = [];
  var len = files.length;
  for (var i = 0; i < len; i++) {
      arr.push({
          uid: i,
          url: files[i],
          status: 'done',
          thumbUrl: files[i]
      });
  }

  return arr;
}

export default setDefaultFileList;
