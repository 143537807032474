import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 1rem;
`;

export const PageTitle = (props) => {
  return (
    <Title>
      {props.children}
    </Title>
  )
}

export default PageTitle;
