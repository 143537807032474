import React, { Component } from 'react';
import { Upload, Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { uploadPhoto } from '../actions/ListingActions';

class UploadImage extends Component {

  constructor(props){
    super(props);

    this.state = {
      fileList: this.props.defaultFileList || [],
      images: this.props.images || []
    }
  }

  render(){

    const props = {
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          // Get index of removed image
          const index = fileList.indexOf(file);

          // Create new arrays
          const newImageList = this.state.images;
          const newFileList = fileList.slice();

          // Remove the image
          newImageList.splice(index, 1);
          newFileList.splice(index, 1);

          // Update the images state & pass up
          this.setState({ images: newImageList });
          console.log(this.state.images)
          this.props.setImages(this.state.images);

          // return the new fileList
          return {
            fileList: newFileList,
          };
        });
      },
      multiple: true,
      defaultFileList: this.props.defaultFileList,
      listType: 'picture-card',
      customRequest: ({ onSuccess, onError, file }) => {
        return onSuccess(null, file);
      },
      beforeUpload: (file, fileList) => {
        return this.props.uploadPhoto(file).then(image => {
          console.log(image);
          const newState = [...this.state.images, image];
          const newList = [...this.state.fileList, file];

          this.setState({ fileList: newList })
          this.setState({ images: newState });

          this.props.setImages(this.state.images)

          return true;

        }).catch((err) => {
          console.log('error', err)
        })
      }
    };

    return (
      <Upload {...props}>
        <Button>
          <Icon type="upload" /> Select File
        </Button>
      </Upload>
    )
  }
}

export default connect(null, { uploadPhoto })(UploadImage);
