import { GET_CURRENT_USER, LOGOUT } from '../actions/UserActions';

export default function(state = { loading: true, authenticated: false}, action) {
  switch(action.type) {
    case GET_CURRENT_USER:
      return { loading: false, authenticated: true, ...action.payload };
    case LOGOUT:
      return { loading: false, authenticated: false };
    default:
      return state;
  }
}
