import React from 'react';
import { Avatar, Divider, Button, Rate } from 'antd';
import styled from 'styled-components';
import ListingForm from './ListingForm';
import { Link } from 'react-router-dom';
import MessageButton from './MessageButton';
import { withRouter } from 'react-router-dom';
import PageTitle from './PageTitle';
import Helmet from 'react-helmet';

const Img = styled.li`
  list-style: none;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
`;

const ListingHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

const ListingUser = styled.div`
  display: flex;
  align-items: center;
`;

const RatingBox = styled.div`
  border: 1px solid rgba(0,0,0,0.12);
  padding: 1rem;
  border-radius: 5px;
`;

const ListingInfo = (props) => {

  let images =  props.listing.images.map((image, key) => {
    return (
      <Img key={key}>
        <img alt={key} src={image} />
      </Img>
    )
  })
  //
  // const handleDelete = () => {
  //   props.deleteListing(props.id).then(() => {
  //     props.history.push('/delete-success');
  //   })
  // }
  //
  // const handleCancel = () => {
  //   console.log('never mind')
  // }

  let placeType = 'Shared Room';

  switch(props.listing.placeType) {
    case 'entire':
      placeType = 'Entire Place'
      break;

    case 'room':
      placeType = 'Room'
      break;

    default:
      placeType = 'Shared Room';
  }

  return (
    <div>
      <Helmet title={`${props.listing.title} — Kalitx`} />
      <ListingHeader>
        <ListingUser>
          <Avatar style={{marginRight: '1rem'}} src={props.listing.userPhoto} size="large" />
          <p>
            <strong>Host</strong><br />
            {props.listing.userName}
          </p>
        </ListingUser>
        {props.rating > 0
          ?
          <RatingBox>
            <Rate disabled defaultValue={Math.round((props.rating) * 100) / 100} />
            {Math.round((props.rating) * 100) / 100}
          </RatingBox>
          :
            null
        }

      </ListingHeader>
      <Divider />
      <PageTitle>{props.listing.title}</PageTitle>
      <div>{images}</div>
      <p><strong>City</strong> {props.listing.address.city}</p>
      <p><strong>Neighborhood</strong> {props.listing.address.neighborhood}</p>
      <p><strong>Place Type</strong> {placeType}</p>
      <p><strong>Per Night</strong> ${props.listing.amount / 100}</p>
      <p>
        <strong>Notes</strong><br /><br />
        {props.listing.description}
      </p><br />
      <ListingForm
        listing={props.listing}
        id={props.id}
        requests={props.requests}
      /><br />

      {props.listingUser.uid === props.currentUser.uid
        ?
          <div>
            <Link to={`/edit/listing/${props.id}`}><Button icon='edit' size="large" style={{width: '100%'}}>Edit</Button></Link><br/>
          </div>
        :
          <MessageButton listingUser={props.listingUser.uid} currentUser={props.currentUser.uid} />
      }
    </div>
  )
}

export default withRouter(ListingInfo);
