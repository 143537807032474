import React, { Component } from 'react';
import { connect } from 'react-redux';
import UpdateListing from '../components/UpdateListing';
import { uploadPhoto, updateListing } from '../actions/ListingActions';
import { Helmet } from 'react-helmet';

class EditListing extends Component {

  render(){

    const id = this.props.match.params.id;
    const listing = this.props.listings[id];

    const initialValues = {
      title: listing.title,
      amount: listing.amount / 100,
      description: listing.description,
      images: listing.images,
      placeType: listing.placeType,
      address: listing.address.address,
      neighborhood: listing.address.neighborhood,
      city: listing.address.city,
      zip: listing.address.zip,
      state: listing.address.state,
      country: listing.address.country,
      phone: listing.address.phone
    }

    return (
      <div>
      <Helmet title='Edit Listing — Kalitx' />
      {listing.user === this.props.user.uid
        ?
          <UpdateListing initialValues={initialValues} uploadPhoto={this.props.uploadPhoto} updateListing={this.props.updateListing} id={id} />
        :
          <span>You are not authorized to view this page</span>
      }
      </div>
    )
  }
}

export default connect(state => ({
  listings: state.listings,
  user: state.user
}), {  uploadPhoto, updateListing })(EditListing);
