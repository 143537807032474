import React from 'react';
import { Badge } from 'antd';

const ReservationCount = (props) => {
  return (
    <span>
      Requests
      {props.requestCount > 0
        ?
          <React.Fragment>
            &nbsp;<Badge count={props.requestCount} style={{ backgroundColor: '#52c41a' }} />
          </React.Fragment>
        :
          null
      }
    </span>
  )
}

export default ReservationCount;
