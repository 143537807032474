import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';

var config = {
  apiKey: "AIzaSyD6E7DOj9DBYE02__AVOsASa-bI7tl8nOo",
  authDomain: "kalitx-bdd78.firebaseapp.com",
  databaseURL: "https://kalitx-bdd78.firebaseio.com",
  projectId: "kalitx-bdd78",
  storageBucket: "kalitx-bdd78.appspot.com",
  messagingSenderId: "687549044078"
};

const fire = firebase.initializeApp(config);
export const provider = new firebase.auth.FacebookAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const db = firebase.firestore();
export default fire;
