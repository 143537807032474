import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class ProtectedRoute extends Component {
  render() {

    const { component: Component, ...props } = this.props;
    const { authenticated } = props.user;

    return (
      <Route
        {...props}
        render={props => (
          authenticated ?
            <Component {...props} /> :
            <Redirect to='/login' />
        )}
      />
    )
  }
}

export default ProtectedRoute;
