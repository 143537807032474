import React, { Component } from 'react';
import { DateRangePicker, START_DATE, END_DATE } from 'react-dates';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  handleDatesChange = (dates) => {
    const startField = this.props[this.props.startDateFieldName];
    const endField = this.props[this.props.endDateFieldName];
    startField.input.onChange(dates.startDate);
    endField.input.onChange(dates.endDate);
    this.props.setNights(dates);
  }

  handleFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
    if (focusedInput === START_DATE) {
      this.props[this.props.startDateFieldName].input.onFocus();
      return;
    }
    if (focusedInput === END_DATE) {
      this.props[this.props.endDateFieldName].input.onFocus();
      return;
    }
  }

  isDayBlocked = day => {
    let notValid = false;
    const reservations = this.props.reservations;
    Object.keys(reservations).map((key, i) => {
      const dates = [
        moment.unix(reservations[key].startDate.seconds).utc(),
        moment.unix(reservations[key].endDate.seconds).utc()
      ]

      const range = moment.range(dates)

      if (range.contains(day)) {
        notValid = true
      }
      return moment.range(dates)
    })

    return notValid
  }

  render() {
    const startDate = this.props[this.props.startDateFieldName].input.value || null;
    const endDate = this.props[this.props.endDateFieldName].input.value || null;

    return (
      <DateRangePicker
        endDate={endDate}
        endDatePlaceholderText="End Date"
        focusedInput={this.state.focusedInput || null}
        minimumNights={1}
        numberOfMonths={1}
        onDatesChange={this.handleDatesChange}
        onFocusChange={this.handleFocusChange}
        startDate={startDate}
        endDateId="end_date"
        startDateId="start_date"
        startDatePlaceholderText="Start Date"
        isDayBlocked={this.isDayBlocked}
      />
    );
  }
}
export default DatePicker;
