import React from 'react';
import styled from 'styled-components';

const Info = styled.div`
  border: 1px solid rgba(0,0,0,0.12);
  padding: 1rem;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 2rem;

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const ListingPaymentInfo = (props) => {
  return (
    <Info>
      <p><strong>{props.nights} nights</strong> ${(props.amount).toFixed(2)}</p>
      <p><strong>Kalitx Fee</strong> ${(props.amount * .15).toFixed(2)}</p>
      <p><strong>Total</strong> ${(props.amount * 1.15).toFixed(2)}</p>
    </Info>
  )
}

export default ListingPaymentInfo;
