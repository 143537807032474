import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { logout } from '../actions/UserActions';
import { getUserRequests } from '../actions/RequestActions';
import styled from 'styled-components';
import { Avatar, Divider, Icon } from 'antd';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  a {
    padding: 1rem;
    display: block;
    border: 1px solid rgba(0,0,0,0.12);
    margin-bottom: 1rem;
    height: auto !important;
  }
`;

const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  padding: 0;
  display: block;
  border-radius: 4px;
  height: 32px;
`;

class Nav extends Component {

  componentDidUpdate(prevProps){
    if(this.props.user !== prevProps.user){
      this.props.getUserRequests(this.props.user);
    }
  }

  render(){

    return (
      <nav>
        <UserAvatar><Link to={`/profile/${this.props.user.uid}`}><Avatar style={{margin: '0 auto'}}  src={this.props.user.photoURL} size="large" /></Link></UserAvatar>
        <Divider />
        <List>
          <ListItem><StyledLink to='/host'><Icon type="home"style={{ marginRight: '1rem' }}  />List a Space</StyledLink></ListItem>
          <ListItem><StyledLink to='/search'><Icon type="search" style={{ marginRight: '1rem' }} />Search Vacation Rentals and Message People</StyledLink></ListItem>
          <ListItem><StyledLink to='/messages'><Icon type="message" style={{ marginRight: '1rem' }} />Messages</StyledLink></ListItem>
          <ListItem><StyledLink to='/trips'><Icon type="global" style={{ marginRight: '1rem' }} />Trips</StyledLink></ListItem>
          <ListItem><StyledLink to='/payment-settings'><Icon type="credit-card" style={{ marginRight: '1rem' }} />Payment Settings</StyledLink></ListItem>
          <Divider />
          <ListItem><Button onClick={this.props.logout}>Logout</Button></ListItem>
        </List>
      </nav>
    )
  }
}

export default connect(state => ({
  user: state.user,
  requests: state.requests
}), { logout, getUserRequests })(Nav);
