import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListings, getListingUser, getRating } from '../actions/ListingActions';
import { getListingRequests } from '../actions/RequestActions';
import ListingInfo from '../components/ListingInfo';
import calculateRating from '../utils/calculateRating';

class Listing extends Component {

  constructor() {
    super();

    this.state = {
      listingUser: null,
      requests: null,
      rating: null
    }
  }

  componentWillMount(){
    const id = this.props.match.params.id;

    // Get Listing using ID
    this.props.getListings().then(listings => {
      const user = this.props.listings[id].user;

      // Get requests for blackout dates
      this.props.getListingRequests(id).then(res => {
        this.setState({ requests: res })
      })

      // Set ListingUser to check what to show
      this.props.getListingUser(user).then(res => {
        this.setState({ listingUser: res })
      })

      this.props.getRating(id).then(ratings => {
        const rating = calculateRating(ratings);

        this.setState({ rating: rating })
      })
    });
  }

  renderListing = () => {
    const id = this.props.match.params.id;
    const listing = this.props.listings[id];

    return (
      <ListingInfo
        listingUser={this.state.listingUser}
        currentUser={this.props.user}
        listing={listing}
        rating={this.state.rating}
        id={this.props.match.params.id}
        requests={this.props.requests}
      />
    )
  }

  render(){

    return (
      <div>
        {this.props.requests && this.state.listingUser
          ?
            this.renderListing()
          :
            <span>loading listing …</span>
        }
      </div>
    )
  }
}

export default connect(state => ({
  listings: state.listings,
  user: state.user,
  requests: state.requests
}), { getListings, getRating, getListingUser, getListingRequests })(Listing);
