import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.header`
  margin-top: 4rem;
`;

const List = styled.ul`
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const Footer = (props) => {
  return (
    <FooterWrapper>
      <List>
        <li><Link to='/about-us'>About Kalitx</Link> &copy; 2019</li>
        <li><Link to='/terms'>Terms</Link></li>
      </List>
    </FooterWrapper>
  )
}

export default Footer;
