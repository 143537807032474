import { GET_USER_REQUESTS, GET_LISTING_REQUESTS } from '../actions/RequestActions';

export default function( state = {}, action) {
  switch(action.type) {
    case GET_USER_REQUESTS:
      return action.payload;
    case GET_LISTING_REQUESTS:
      return action.payload;
    default:
      return state;
  }
}
