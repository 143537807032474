import { GET_RESERVATIONS, GET_LISTING_RESERVATIONS } from '../actions/ReservationActions';

export default function( state = {}, action) {
  switch(action.type) {
    case GET_RESERVATIONS:
      return action.payload;
    case GET_LISTING_RESERVATIONS:
      return action.payload;
    default:
      return state;
  }
}
