import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from '../actions/UserActions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      register: false,
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    };
  }

  handleNameChange = (e) => {
    const { value } = e.target;
    this.setState({ name: value })
  }

  handleEmailChange = (e) => {
    const { value } = e.target;
    this.setState({ email: value })
  }

  handlePasswordChange = (e) => {
    const { value } = e.target;
    this.setState({ password: value })
  }

  handlePasswordConfirmChange = (e) => {
    const { value } = e.target;
    this.setState({ passwordConfirm: value })
  }

  handleRegister = () => {
    const { name, email, password, passwordConfirm } = this.state;

    if(password === passwordConfirm){
      console.log(`email: ${email}, pass: ${password}, name: ${name}`)
      this.props.createUserWithEmailAndPassword(email, password, name )
      .then(() => {
        this.props.history.push('/')
      })
    } else {
      message.error('Passwords do not match, please correct')
    }
  }

  handleLogin = () => {
    const { email, password } = this.state;
    this.props.signInWithEmailAndPassword(email, password)
    .then(() => {
      this.props.history.push('/')
    })
  }

  toggleRegister = (e) => {
    e.preventDefault()
    this.setState({ register: !this.state.register })
  }

  render() {
    return (
      <Wrapper>
        {this.state.register
          ?
            <Fragment>
              <Input size='large' placeholder='Full Name' onChange={this.handleNameChange} />
              <Input size='large' placeholder='Email' onChange={this.handleEmailChange} />
              <Input size='large' type='password' placeholder='Password' onChange={this.handlePasswordChange} />
              <Input size='large' type='password' placeholder='Confirm password' onChange={this.handlePasswordConfirmChange} />
              <Button size='large' type='primary' onClick={this.handleRegister}>Register</Button>
              <a href='#login' onClick={(e) => { this.toggleRegister(e) }}>Already have an account? Sign In</a>
            </Fragment>
          :
          <Fragment>
            <Input size='large' placeholder='Email' onChange={this.handleEmailChange} />
            <Input size='large' type='password' placeholder='Password' onChange={this.handlePasswordChange} />
            <Button size='large' type='primary' onClick={this.handleLogin}>Login</Button>
            <a href='#register' onClick={(e) => { this.toggleRegister(e) }}>Don't have an account? Sign Up</a>
          </Fragment>

        }
      </Wrapper>
    );
  }

}

export default withRouter(connect(null, {signInWithEmailAndPassword, createUserWithEmailAndPassword})(LoginForm));
