import React from 'react';
import { Card, Icon, Avatar } from 'antd';
import moment from 'moment';
const { Meta } = Card;

const RequestCard = (props) => {

  const handleAccept = (request, id) => e => {
    e.preventDefault();

    return props.accept(request, id)
    .then(() => {
      return props.charge(request)
    })
    .then(() => {
      return props.reject(id);
    }).then(() => {
      return props.fetch(props.user)
    });
  }

  const handleReject = (id) => e => {
    e.preventDefault();
    return props.reject(id).then(() => {
      props.fetch(props.user)
    });
  }

  const startDate = moment.unix(props.request.startDate.seconds).format('LL');
  const endDate = moment.unix(props.request.endDate.seconds).format('LL');
  const amount = ((props.request.listing.amount / 1.15) / 100).toFixed(2);

  return (
    <Card
      style={{ marginBottom: '2rem' }}
      cover={<img alt="example" src={props.request.listing.image} />}
      actions={[<a href='#' onClick={handleAccept(props.request, props.id)}><Icon type="check" /> Accept</a>,
                <a href='#' onClick={handleReject(props.id)}><Icon type="close" /> Reject</a>]}
      >
    <Meta
      avatar={<Avatar src={props.request.requester.photoURL} />}
      title={props.request.listing.title}
      description={`${props.request.requester.name} requests from ${startDate} to ${endDate} / $${amount}`}
    />
  </Card>
  )
}

export default RequestCard;
