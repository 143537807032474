import { GET_USER_CHARGES } from '../actions/ChargeActions';

export default function(state = { loading: true}, action) {
  switch(action.type) {
    case GET_USER_CHARGES:
      return action.payload;
    default:
      return state;
  }
}
