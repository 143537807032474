import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import { Link } from 'react-router-dom';

const HeaderWrapper = styled.header`
  margin-bottom: 4rem;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-size: 16px;
  margin-top: 1rem;
  opacity: .7;
`;

const Header = (props) => {
  return (
    <HeaderWrapper>
      <Link to='/'>
        <Logo />
        <Subtitle>Home-Sharing Vacation Rentals</Subtitle>
      </Link>
    </HeaderWrapper>
  )
}

export default Header;
