import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Input, Button } from 'antd';
import { createUserWithEmailAndPassword } from '../actions/UserActions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr 1fr;
`;

class RegisterForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleEmailChange = (e) => {
    const { value } = e.target;
    this.setState({ email: value })
  }

  handlePasswordChange = (e) => {
    const { value } = e.target;
    this.setState({ password: value })
  }

  handleClick = () => {
    const { email, password } = this.state;
    this.props.createUserWithEmailAndPassword(email, password)
  }

  render() {
    return (
      <Wrapper>
        <Input size='large' placeholder='Email' onChange={this.handleEmailChange} />
        <Input size='large' type='password' placeholder='Password' onChange={this.handlePasswordChange} />
        <Button size='large' type='primary'>Register</Button>
      </Wrapper>
    );
  }

}

export default connect(null, {createUserWithEmailAndPassword})(RegisterForm);
