import {db} from '../firebase';

export const GET_MESSAGES = 'get_messages';

export function getMessages(chat){
  return dispatch =>
    db.collection('chats').doc(chat).collection('messages').orderBy('created_at').get().then(snapshot => {
      const messages = {};

      snapshot.forEach((doc) => {
        messages[doc.id] = doc.data();
      })

      dispatch({
        type: GET_MESSAGES,
        payload: messages
      })
    })
}

export function sendMessage(values, chat, user1, user2){
  return dispatch =>
    db.collection('chats').doc(chat).collection('messages').doc()
    .set({
      text: values.message,
      created_at: Date.now(),
      user: user1
    }, { merge: true }).then(() => {
      db.collection('chats').doc(chat)
      .set({
        lastMessage: values.message,
        created_at: Date.now(),
        users: {
          [user1]: true,
          [user2]: true
        }
      }, { merge: true })
    })
}
