import React, { Component } from 'react';
import PageTitle from '../components/PageTitle';

class Terms extends Component {
  render(){
    return (
      <div>
        <PageTitle>Terms</PageTitle>
        <p>All hosts are enrolled in the Kalitx Insurance Program, liability insurance for up to $3 million USD.</p>
        <p>Kalitx does not provide your information to third parties unless required to do so by law.</p>
        <p>By using this website, you agree to these <strong>Terms of Use</strong>.</p>
        <p>In no circumstance shall Kalitx or its owners, managers, members, officers, employees be liable to you or any third party. Parties agree that they are 18 years and older.</p>
        <p>Kalitx allows people to connect to and pay other people who need a place to live and people who have a place to rent. Kalitx also serves as an arbitrator or mediator in the event of disputes or disagreements. </p>
        <p>No user should contact the other regarding any other transaction, links, emails, contact information, websites, personal or commercial endeavor. The site is not for broker use. You are responsible for the accuracy of all the information you provide. Kalitx is not responsible for the representation and accuracy of identities that a user submits.</p>
        <p>The stay is only for the individual who made the reservation and no other guests may be brought on the host's premises or property. Guests may also not assign nor sublease the property. Guests also must hold Host harmless for any accidents that a guest experiences. The Guest accepts the risk of any damage or theft of personal property brought to the property and holds Host and Kalitx harmless. Guest also holds Host harmless for any injury or illness that occurs on the premises. Guest agrees to leave the property as they found it and maintain the cleanliness. During the stay, if a Guest fails to maintain cleanliness, Host may be entitled to reimbursement or payment of any associated cleaning fees. If a guest fails to comply with the obligations or fails to respect to the Host or cause any physical or psychological harm, or any other objectionable conduct, Guest shall surrender the property, remove all guest's property and not receive any compensation for the remainder of their stay. Any damage done to the property by the Guest may entitle the Host to reimbursement. With notification Host or any Host representative or service person may enter the property or room for repair, inspection, or give a showing. Any keys or other personal property lent to the Guest must be returned upon check-out. Failure to do so may result in reimbursement or other disciplinary charges. Guest may not build or alter the property in any way. Guest agrees to use any appliances for their intended uses.
        </p>
        <p>Kalitx reserves the right to suspend or terminate any user (lister or buyer) account for whatever reason. Kalitx curates the listings on the site and not every listing may be suitable for the site. This curation is based on the sole discretion of Kalitx. Host and Guest agree to hold harmless Kalitx from any liability arising between Host, Guest and any site users. Once a reservation has been made by the Guest and accepted by the Host, there will be no reimbursement or refund for cancellations to the reservations made by the Guest.</p>
        <p>In the event of non-payment by a Guest, Kalitx does not guarantee payment to Listers. If Host is unable to provide the property to the Guest, the Guest is entitled to reimbursement plus any administration fees to be paid by the Host.</p>
        <p>It is the responsibility of the listers and buyers to adhere to all local laws in their jurisdiction.</p>
        <p>In the event that an issue arrises where Host and Guest are unable to resolve the matter, Kalitx will arbitrate and all decisions are legally binding.</p>
        <p>Kalitx does not provide your information to third parties unless required to do so by law.</p>
        <p>If any site user, Host or Guest tries to complete the transaction outside of Kalitx, for example request or demand payment outside the site, please call us immediately to report the incident to a Kalitx Relationship Manager at <a href="tel:3477156046">(347) 715-6046</a></p>
      </div>
    )
  }
}

export default Terms;
