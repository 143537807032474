import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { uploadPhoto, updateUserPhoto, getCurrentUser, updateUserBio } from '../actions/UserActions';
import { Avatar, Button, Card, Divider, Upload, Icon, message, Input } from 'antd';
import DeleteAccountButton from '../components/DeleteAccountButton';
import EditPhotos from '../components/EditPhotos';
import { Helmet } from 'react-helmet';

const NewAvatar = styled.img`
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: cover;
  margin: 1rem 0;
`;

function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJPG && isLt2M;
}


class EditProfile extends Component {

  constructor(props){
    super(props)

    this.state = {
      loading: false,
      image: '',
      bio: this.props.user.bio,
      submitting: false
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    this.props.uploadPhoto(file).then(res => {
      this.setState({ image: res})
      onSuccess('ok');
    })
  };

  handleBioChange = (e) => {
    console.log(e.target.value)
    this.setState({ bio: e.target.value })
  }

  handleChange = (info) => {
   if (info.file.status === 'uploading') {
     this.setState({ loading: true });
   }
   if (info.file.status === 'done') {
     this.setState({ loading: false })
     message.success('Image Uploaded')
   }
 }

 handleSubmit = () => {
   const user = this.props.user.uid;
   const image = this.state.image;

   this.setState({ submitting: true })
   this.props.updateUserPhoto(user, image).then(() => {
     this.setState({ submitting: false, image: undefined, loading: false });
     this.props.getCurrentUser()
     message.success('Image updated!');
   })
 }

 handleSubmitBio = () => {
   const { user } = this.props;
   const { bio } = this.state;

   this.setState({ submitting: true })
   this.props.updateUserBio(user.uid, bio)
   .then(() => {
     this.setState({ submitting: false, loading: false });
     this.props.getCurrentUser()
     message.success('Bio updated!');
   })
 }

  render(){
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { image, bio } = this.state;
    return (
      <div>
        <Helmet title='Edit Profile — Kalitx'/>
        <Card title='Avatar'>
          <Avatar size='large' src={this.props.user.photoURL} />
          <br/><br/>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={this.dummyRequest}
            onChange={this.handleChange}
          >
            {image ? <NewAvatar src={image} alt="avatar" /> : uploadButton}
          </Upload>
          <Button onClick={this.handleSubmit} size='large' type='primary' loading={this.state.submitting} disabled={!image}>Upload Photo</Button>
        </Card>
        <Divider/>
        <Card title='Bio'>
          <Input placeholder='Tell us a bit about yourself' value={bio} onChange={this.handleBioChange} /><br/><br/>
          <Button onClick={this.handleSubmitBio} loading={this.state.submitting}>Save</Button>
        </Card>
        <Divider/>
        <EditPhotos />
        <Divider/>
        <DeleteAccountButton/>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}), { getCurrentUser, uploadPhoto, updateUserPhoto, updateUserBio })(EditProfile);
