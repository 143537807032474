import React, { Component } from 'react';
import { Carousel, Divider } from 'antd';
import { connect } from 'react-redux';
import { getUserPhotos } from '../actions/UserActions';
import styled from 'styled-components';

const Slide = styled.div`
  height: 50vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

class UserPhotos extends Component {

  constructor(props){
    super(props)

    this.state = {
      isLoaded: false,
      photos: []
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.profileUser !== prevProps.profileUser){
      this.props.getUserPhotos(this.props.profileUser)
      .then(photos => {
        this.setState({ isLoaded: true, photos: photos})
      })
    }
  }

  renderPhotos = () => {
    return Object.keys(this.state.photos).map((photo, index) => {
      return (
        <Slide key={index}>
          <img alt='user' src={this.state.photos[photo].url} />
        </Slide>
      )
    })
  }

  render(){
    return (
      <div>
        {this.state.isLoaded
          ?
            <div>
              <h1>Photos</h1>
              <Carousel>
                {this.renderPhotos()}
              </Carousel>
              <Divider />
              </div>
          :
            null
        }
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}), { getUserPhotos })(UserPhotos);
