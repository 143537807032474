import React from 'react';
import styled from 'styled-components';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Button, message } from 'antd';

const Label = styled.label`
  width: 100%;

  .StripeElement {
    border: 1px solid rgba(0,0,0,0.12);
    padding: 1rem;
    margin: 1rem 0;

    input {
      border: 1px solid rgba(0,0,0,0.12);
    }
  }
`;

class CardForm extends React.Component {

  success = () => {
    message.success('Success! Card added');
  };

  error = () => {
    message.error('There was an error')
  }

  handleSubmit = (e) => {

    e.preventDefault();

    return this.props.stripe.createToken({type: 'card', name: this.props.user.name}).then(({token}) => {
      var data = token.card;
      return this.props.addCard(this.props.user.uid, data, token.id);
    }).then(res => {
      this.props.fetchCards(this.props.user.uid);
      this.success()
      this._element.clear();
    }).catch(err => {
      this.error()
    });
  }

  render() {
    var style = {
      base: {
        fontSize: '16px',
        color: "#32325d",
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#ccc',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    };
    return (
      <form onSubmit={this.handleSubmit}>
        <Label>
          Add card
          <CardElement style={style} elementRef={(c) => this._element = c} />
          </Label>
        <Button icon='credit-card' size='large' type='primary' htmlType='submit'>Add Card</Button>
      </form>
    )
  }
}

export default injectStripe(CardForm);
