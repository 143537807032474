import { db } from '../firebase';

export const GET_CARDS = 'get_cards';

export function addCard(user, data, token) {
  return dispatch =>
    db.collection('users').doc(user).collection('sources').doc(token).set({
      address_zip: data.address_zip,
      brand: data.brand,
      country: data.country,
      exp_month: data.exp_month,
      exp_year: data.exp_year,
      funding: data.funding,
      id: data.id,
      last4: data.last4,
      object: data.object
    })
}

export function removeCard(user, card) {
  return dispatch =>
    db.collection('users').doc(user).collection('sources').doc(card).delete()
}

export function fetchCards(user) {
  return dispatch =>
    db.collection('users').doc(user).collection('sources').get().then(snapshot => {

      const cards = {};

      snapshot.forEach((doc) => {
        cards[doc.id] = doc.data();
      })

      dispatch({
        type: GET_CARDS,
        payload: cards
      })
    })
}
