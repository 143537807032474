import React from 'react';
import { Card, Icon, Divider } from 'antd';

const CardList = (props) => {

  const handleRemove = (user, card) => e => {
    e.preventDefault();
    props.removeCard(user, card).then(() => {
      props.fetchCards(user)
    });
  }

  let cards = Object.keys(props.cards).map((card, key) => {
    return (
      <Card
        key={key}
        type="inner"
        title={`${props.cards[card].brand}`}
        extra={<a href='#' onClick={handleRemove(props.user.uid, card)}>Remove</a>}
        style={{ marginBottom: '1rem' }}
      >
        <span>{props.cards[card].last4}</span>
        <Divider type='vertical' />
        <span>{props.cards[card].exp_month} / {props.cards[card].exp_year}</span>
      </Card>
    )
  })

  return (
    <Card title={<span><Icon type='credit-card' /> &nbsp;&nbsp;My Cards</span>}>
      {cards}
    </Card>
  )
}

export default CardList;
