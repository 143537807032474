import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../actions/UserActions';
import { Button, Divider } from 'antd';
import { Helmet } from 'react-helmet';
import LoginForm from '../components/LoginForm';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`

const Note = styled.p`
  color: rgba(0,0,0,0.4);
  margin: 2rem 0 ;
  font-size: 14px;
  font-weight: 100;
`;

class Login extends Component {
  render(){
    return (
      <Wrapper>
        <Helmet title='Login — Kalitx' />
        <Button icon='facebook' size='large' type='primary' onClick={this.props.login}>Continue with Facebook</Button>
        <Note>Welcome, we never post anything to Facebook without your permission.</Note>
        <Divider><Note>Or Login with Email</Note></Divider>
        <LoginForm />
      </Wrapper>
    )
  }
}

export default connect(null, {login})(Login);
