import React, { Component } from 'react';
import { InstantSearch, Hits, SearchBox } from 'react-instantsearch/dom';
import UserCard from '../components/UserCard.js';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const StyledSearchBox = styled(SearchBox)`
  margin-bottom: 2rem;
`;

const StyledHits = styled(Hits) `
  .ais-Hits-item {
    width: 100%;
    border: 0;
    box-shadow: none;
    margin: 0 0 0 1rem;
  }
`

export default class Search extends Component {
  render(){
    return (
      <div>
        <Helmet title='Search — Kalitx' />
        <InstantSearch appId="UNVM4G1EB5" apiKey="8e70896aad27c43ad426897f9a1a561c" indexName="users">
          <StyledSearchBox/>
          <StyledHits hitComponent={UserCard} />
        </InstantSearch>
      </div>
    )
  }
}
