import { db } from '../firebase';

export const GET_USER_CHARGES = 'get_user_charges';

export function chargeUser(request) {
  const today = new Date();

  return dispatch =>
    db.collection('users').doc(request.requester.uid).collection('charges').add({
      date: today,
      title: request.listing.title,
      startDate: request.startDate,
      endDate: request.endDate,
      amount: request.listing.amount,
      currency: 'usd',
      customer: request.requester.customer_id,
      lister: request.listingUser,
      lister_name: request.requester.name,
      type: 'charge'
    }).then(() => {
        db.collection('users').doc(request.listingUser).collection('charges').add({
          date: today,
          title: request.listing.title,
          startDate: request.startDate,
          endDate: request.endDate,
          amount: request.listing.amount,
          currency: 'usd',
          customer: request.requester.customer_id,
          lister: request.listingUser,
          lister_name: request.requester.name,
          type: 'credit'
        })
    })
}

export function getUserCharges(user) {
  return dispatch =>
    db.collection('users').doc(user).collection('charges').get().then(snapshot => {

      const charges = {};

      snapshot.forEach((doc) => {
        charges[doc.id] = doc.data();
      })

      dispatch({
        type: GET_USER_CHARGES,
        payload: charges
      })
    })
}
