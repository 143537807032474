import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ListingReducer from './ListingReducer';
import UserReducer from './UserReducer';
import ChatReducer from './ChatReducer';
import MessageReducer from './MessageReducer';
import RequestReducer from './RequestReducer';
import ChargeReducer from './ChargeReducer';
import CardReducer from './CardReducer';
import TripReducer from './TripReducer';
import ReservationReducer from './ReservationReducer';

const rootReducer = combineReducers({
  form: formReducer,
  user: UserReducer,
  listings: ListingReducer,
  messages: MessageReducer,
  chats: ChatReducer,
  requests: RequestReducer,
  charges: ChargeReducer,
  cards: CardReducer,
  trips: TripReducer,
  reservations: ReservationReducer
});

export default rootReducer;
