import React, { Component } from 'react';
import { getUserReferences } from '../actions/UserActions';
import { Button, Divider, Avatar } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Ref = styled.li`
  list-style: none;
  display: flex;

  strong, span {
    margin-right: 1rem;
  }
`;

class UserReferences extends Component {

  state = {
    references: []
  }

  componentDidUpdate(prevProps){
    if(prevProps.profileUser !== this.props.profileUser){
      this.props.getUserReferences(this.props.profileUser)
      .then(references => {
        this.setState({ references: references })
      })
    }
  }

  renderReferences = () => {
    return Object.keys(this.state.references).map((ref, index) => {

      const { photo, text, name } = this.state.references[ref];

      return (
        <Ref key={index}>
          <p><Avatar size={30} src={photo} /></p>
          <p><strong>{name}</strong></p>
          <p>{text}</p>
        </Ref>
      )
    })
  }

  render() {

    const { references } = this.state;

    return (
      <div>
        {Object.keys(references).length > 0
            // No references
          ?
            // If this is their page
            this.props.profileUser === this.props.user.uid
              ?
                <>
                  <h1>References</h1>
                  {this.renderReferences()}
                  <p><Link to='/request-reference'><Button type='primary'>Request a Reference</Button></Link></p>
                  <Divider />
                </>

              // If this is not their page
              :
                <>
                  <h1>References</h1>
                  {this.renderReferences()}
                  <Divider />
                </>
            // User has references
          :
            this.props.profileUser === this.props.user.uid

            // If this is their page
              ?
                <>
                  <h1>References</h1>
                  <p>You have no references</p>
                  <p><Link to='/request-reference'><Button type='primary'>Request a Reference</Button></Link></p>
                  <Divider />
                </>

              // If this is not their page
              :
                null
        }
      </div>
    );
  }

}

export default connect(state => ({
  user: state.user,
}), { getUserReferences })(withRouter(UserReferences));
