import React, { Component } from 'react';
import UserListings from '../components/UserListings';
import styled from 'styled-components';
import { db } from '../firebase';
import { connect } from 'react-redux';
import { Button, Avatar } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Popup from '../components/Popup';
import MessageButton from '../components/MessageButton';
import PageTitle from '../components/PageTitle';
import { deleteUser } from '../actions/UserActions';
import DeleteAccountButton from '../components/DeleteAccountButton';
import { device } from '../utils/devices';
import { Helmet } from 'react-helmet';
import UserPhotos from '../components/UserPhotos';
import UserReferences from '../components/UserReferences';
import UserRatings from '../components/UserRatings';
import OverallRating from '../components/OverallRating';

const Section = styled.section`
  margin-bottom: 2rem;
`;

const ProfileHeader = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0,0,0,0.12);
`;

const ProfileActions = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr 1fr;

  @media ${device.device} {
    button {
      width: 100%;
    }
  }

  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
  }
`;

const Bio = styled.div`
  margin-top: 2rem;
  font-size: 14px;
`;

class Profile extends Component {

  constructor(props){
    super(props)

    this.state = {
      popupOpen: false,
      profileUser: []
    }
  }

  checkBankInfo = () => {
    if (!this.props.user.bankInfo){
      this.setState({ popupOpen: true})
    } else {
      this.props.history.push('/add-listing')
    }
  }

  componentDidMount(){
    const ref = db.collection('users').where('uid', '==', this.props.match.params.id );

    ref.get().then(user => {

      this.setState({
        profileUser: user.docs[0].data()
      })

    }).catch(function(error){
      console.log('Error retrieving user', error)
    })
  }

  render(){
    return (
      <div>
        <Helmet title='My Listings — Kalitx' />
        <Popup title="Please add bank info" visible={this.state.popupOpen}>
          Before you can add a listing, please enter your bank information in the <Link to='/payment-settings'>Payment Settings</Link> page
        </Popup>
        <Section>
          <PageTitle>
          <ProfileHeader>
            <Avatar size={50} src={this.state.profileUser.photoURL} /><br/><br/>
            {this.state.profileUser.name}
            <Bio>
              {this.state.profileUser.bio
                ?
                  <p>{this.state.profileUser.bio}</p>
                :
                  null
              }
            </Bio>
          </ProfileHeader>
          </PageTitle>
          <UserReferences profileUser={this.state.profileUser.uid} />
          <OverallRating profileUser={this.state.profileUser.uid} />
          <UserRatings profileUser={this.state.profileUser.uid} />
          <UserPhotos profileUser={this.state.profileUser.uid}/>
          {this.props.user.uid
            ?
              <section>
                <h1>Listings</h1>
                <UserListings user={this.props.match.params.id} />
                <br />
                {this.props.user.uid === this.props.match.params.id
                  ?
                    <ProfileActions>
                      <Button type='primary' onClick={this.checkBankInfo} size='large'>Add Listing</Button>
                      <Link to='/edit-profile'><Button size='large'>Edit Profile</Button></Link>
                      <DeleteAccountButton user={this.props.user} deleteUser={this.props.deleteUser} />
                    </ProfileActions>
                  :
                      <MessageButton listingUser={this.props.match.params.id} currentUser={this.props.user.uid} />
                }
              </section>
            :
              <span>loading listings</span>
          }
        </Section>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}), { deleteUser })(withRouter(Profile));
