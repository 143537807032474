import React, { Component } from 'react'
import { Button, Input, Card } from 'antd'
import { addReference } from '../actions/UserActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import qs from 'qs'

const { TextArea } = Input;

class AddReference extends Component {

  state = {
    text: '',
    submitting: false,
    sent: false
  }

  handleChange = (e) => {
    const text = e.target.value;
    this.setState({ text: text })
  }

  handleSubmit = () => {

    const { text } = this.state;

    const query = qs.parse(this.props.location.search);
    const { uid } = this.props.match.params;
    const { photoURL, name } = this.props.user;

    this.setState({ submitting: true })
    this.props.addReference(uid, photoURL, name, text )
    .then(() => {
      this.setState({ submitting: false, sent: true })
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {

    const { text, submitting, sent } = this.state;

    const query = qs.parse(this.props.location.search);
    const { requester } = query;

    return (
      <Card title={`${requester} has requested a reference`}>

        <Helmet title='Add a Reference — Kalitx' />

        {sent
          ?
            <>
              Thank you for submitting your reference!
            </>
          :
            <>
              <p><TextArea onChange={this.handleChange} size='large' value={text} /></p>
              <p><Button onClick={this.handleSubmit} type='primary' size='large' loading={submitting}>Submit Reference</Button></p>
            </>
        }
      </Card>
    );
  }

}


export default withRouter(connect(state => ({
  user: state.user,
}), { addReference })(AddReference));
