import { db } from '../firebase';

export const GET_USER_REQUESTS = 'get_user_requests';
export const GET_LISTING_REQUESTS = 'get_listing_requests';

export function addRequest(listing, requester, values, id) {

  const setNights = (start, end) => {
    return Math.abs(end.diff(start, 'days'));
  }

  const request = db.collection('requests');
  return dispatch =>
    request.add({
      listingUser: listing.user,
      listing: {
        id: id,
        title: listing.title,
        image: listing.images[0],
        amount: Math.round(setNights(values.start, values.end) * listing.amount * 1.15)
      },
      startDate: values.start.toDate(),
      endDate: values.end.toDate(),
      requester: requester,
      accepted: false
    })
}

export function acceptRequest(request, id) {
  return dispatch =>
    db.collection('trips').doc(id).set(request).then(() => {
      db.collection('reservations').doc(id).set(request)
    })
}

export function getUserRequests(user) {

  return dispatch =>
    db.collection('requests').where('listingUser', '==', user.uid).get().then(snapshot => {
      const requests = {};

      snapshot.forEach((doc) => {
        requests[doc.id] = doc.data();
      })

      dispatch({
        type: GET_USER_REQUESTS,
        payload: requests
      })
    })
}

export function removeRequest(request) {
  return dispatch =>
    db.collection('requests').doc(request).delete();
}

export function getListingRequests(id) {

  return dispatch =>
     db.collection('requests').where('listing.id', '==', id).get().then(snapshot => {
       const requests = {};

       snapshot.forEach((doc) => {
         requests[doc.id] = {
           startDate: doc.data().startDate,
           endDate: doc.data().endDate,
         };
       })

       dispatch({
         type: GET_LISTING_REQUESTS,
         payload: requests
       })
    })
}
