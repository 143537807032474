import React from 'react';
import moment from 'moment';

const Date = (props) => {

  const time = moment(props.time).fromNow();

  return (
    <span>
      {time}
    </span>
  )
}

export default Date;
