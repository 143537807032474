import React, { Component } from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import userChat from '../utils/userChat.js';

class MessageButton extends Component {


  render(){

    const chatLink = userChat(this.props.listingUser, this.props.currentUser);

    return (
      <Button icon='message' onClick={() => this.props.history.push(`/messages/${chatLink}`) } size="large" style={{width: '100%'}} >Message</Button>
    )
  }
}

export default withRouter(MessageButton);
