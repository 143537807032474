import React from 'react';
import { Card, Divider, Rate } from 'antd';
import moment from 'moment';
import RateTrip from './RateTrip';
const { Meta } = Card;

const TripCard = (props) => {

  const startDate = moment.unix(props.trip.startDate.seconds).format('LL');
  const endDate = moment.unix(props.trip.endDate.seconds).format('LL');

  return (
    <Card
      style={{ marginBottom: '2rem' }}
      cover={<img alt="example" src={props.trip.listing.image} />}
      >
    <Meta
      title={props.trip.listing.title}
      description={`from ${startDate} to ${endDate}`}
    />
    {props.ratingOpen && !props.trip.rating
      ?
        <div>
          <Divider />
          <RateTrip trip={props.trip} id={props.id} />
        </div>
      :
        <React.Fragment>
          {props.ratingOpen
            ?
              <div>
                <Divider />
                <Rate disabled defaultValue={props.trip.rating} style={{ color: '#1890ff' }} />
                {props.trip.rating && <span className="ant-rate-text">{props.trip.rating} stars</span>}
              </div>
            :
              null
          }
        </React.Fragment>
    }
  </Card>
  )
}

export default TripCard;
