import React, { Component } from 'react';
import { getUserRatings } from '../actions/UserActions';
import { Avatar, Button, Divider, Rate } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Rating = styled.li`
  list-style: none;
  display: flex;
  margin-top: 1rem;

  strong {
    margin: 0 1rem .5rem 0;
    display: block;
  }

  .ant-avatar {
    margin-right: 1rem;
  }
`;

const Info = styled.div`
`;

const User = styled.div`
  display: flex;
`;

class UserRatings extends Component {

  state = {
    isLoaded: false,
    ratings: []
  }

  componentDidUpdate(prevProps){
    if(prevProps.profileUser !== this.props.profileUser){
      this.props.getUserRatings(this.props.profileUser)
      .then(ratings => {
        this.setState({ ratings: ratings, isLoaded: true })
      })
    }
  }

  renderRatings = () => {
    return Object.keys(this.state.ratings).map((item, index) => {

      const { name, comment, photoURL, rating } = this.state.ratings[item];

      return (
        <Rating key={index}>
          <Avatar src={photoURL} />
          <Info>
            <div><strong>{name}</strong></div>
            <div>{comment}</div>
            <Rate disabled defaultValue={rating} />
          </Info>
        </Rating>
      )
    })
  }

  render() {

    const { ratings, isLoaded } = this.state;

    return (
      <div>
        {isLoaded
          ?
            Object.keys(ratings).length > 0
              ?
                <>
                  <h1>Review Summary</h1>
                  {this.renderRatings()}
                  <Divider />
                </>
              :
                null
          :
            null
        }
      </div>
    );
  }

}

export default connect(state => ({
  user: state.user,
}), { getUserRatings })(UserRatings);
