import { GET_LISTINGS } from '../actions/ListingActions';

export default function( state = {}, action) {
  switch(action.type) {
    case GET_LISTINGS:
      return action.payload;
    default:
      return state;
  }
}
