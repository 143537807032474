import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DeleteSuccess extends Component {
  render(){
    return (
      <div>
        <Helmet title='Listing Deleted — Kalitx' />
        <p>You have successfully deleted this listing</p>
      </div>
    )
  }
}

export default DeleteSuccess;
