const userFromChatURL = (url, user) => {
  const fields = url.split('_');
  const valuesToRemove = ['chat', user];

  const fieldsWithout = fields.filter((i) => (valuesToRemove.indexOf(i) === -1))

  return fieldsWithout[0];
}

export default userFromChatURL;
