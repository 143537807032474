import React, { Component } from 'react';
import { Modal } from 'antd';

export default class Popup extends Component {

  constructor(props){
    super(props)

    this.state = {
      visible: this.props.visible
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.visible === true) {
      this.setState({
        visible: true
      })
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  }
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <div>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}
