import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserPhotos, uploadProfilePhoto, getCurrentUser, deleteUserPhoto } from '../actions/UserActions';
import { Card, message, Icon, Upload, Modal } from 'antd';

class EditPhotos extends Component {
  constructor(props){
    super(props)

    this.state = {
      previewVisible: false,
      previewImage: '',
      loading: false,
      submitting: false,
      fileList: []
    }
  }

  componentDidMount(){
    this.props.getUserPhotos(this.props.user.uid)
    .then(photos => {
      this.setState({ fileList: Object.values(photos) })
    })
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  dummyRequest = ({ file, onSuccess }) => {
    this.props.uploadProfilePhoto(this.props.user.uid, file).then(res => {
      this.setState({ image: res})
      onSuccess('ok');
    })
  };

  handleRemove = (file) => {
    this.props.deleteUserPhoto(this.props.user.uid, file.uid).then(() => {
      message.success('Photo deleted')
    })
  }

  handleChange = ({ fileList }) => this.setState({ fileList })

  render(){

    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Card title='Photos'>
        <Upload
          customRequest={this.dummyRequest}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
          >
          {fileList.length >= 10 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Card>
    )
  }
}

export default connect(state => ({
  user: state.user,
}), { getUserPhotos, uploadProfilePhoto, getCurrentUser, deleteUserPhoto })(EditPhotos);
