import React, { Component } from 'react';

class ListingSuccess extends Component {
  render(){
    return (
      <div>
        <p>Your listing has been successfully created.</p>
      </div>
    )
  }
}

export default ListingSuccess;
