import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addListing, uploadPhoto } from '../actions/ListingActions';
import { Field, reduxForm } from 'redux-form';
import { Button, Divider } from 'antd';
import UploadImage from '../components/UploadImage';
import { SelectField, TextField, NumberField } from 'redux-form-antd';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const required = value => (value ? undefined : 'Required')
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength100 = maxLength(100)
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength10 = minLength(10)
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

class AddListing extends Component {

  constructor(props){
    super(props);

    this.state = {
      images: [],
      submitting: false
    }
  }

  onSubmit = (values) => {
    this.setState({ submitting: true })
    this.props.addListing(values, this.state.images, this.props.user).then(ref => {
      this.setState({ submitting: false })
      this.props.history.push('listing-success');
    });
  }

  setImages = (images) => {
    this.setState({ images: images })
  }

  render(){

    const { handleSubmit } = this.props;

    const options = [
      { label: "Room", value: "room" },
      { label: "Shared Room", value: "shared"},
      { label: "Entire Place", value: "entire" },
    ];

    const states = [
      {
          "label": "Alabama",
          "value": "Alabama"
      },
      {
          "label": "Alaska",
          "value": "Alaska"
      },
      {
          "label": "Arizona",
          "value": "Arizona"
      },
      {
          "label": "Arkansas",
          "value": "Arkansas"
      },
      {
          "label": "California",
          "value": "California"
      },
      {
          "label": "Colorado",
          "value": "Colorado"
      },
      {
          "label": "Connecticut",
          "value": "Connecticut"
      },
      {
          "label": "Delaware",
          "value": "Delaware"
      },
      {
          "label": "District Of Columbia",
          "value": "name"
      },
      {
          "label": "Florida",
          "value": "Florida"
      },
      {
          "label": "Georgia",
          "value": "Georgia"
      },
      {
          "label": "Hawaii",
          "value": "Hawaii"
      },
      {
          "label": "Idaho",
          "value": "Idaho"
      },
      {
          "label": "Illinois",
          "value": "Illinois"
      },
      {
          "label": "Indiana",
          "value": "Indiana"
      },
      {
          "label": "Iowa",
          "value": "Iowa"
      },
      {
          "label": "Kansas",
          "value": "Kansas"
      },
      {
          "label": "Kentucky",
          "value": "Kentucky"
      },
      {
          "label": "Louisiana",
          "value": "Louisiana"
      },
      {
          "label": "Maine",
          "value": "Maine"
      },
      {
          "label": "Maryland",
          "value": "Maryland"
      },
      {
          "label": "Massachusetts",
          "value": "Massachusetts"
      },
      {
          "label": "Michigan",
          "value": "Michigan"
      },
      {
          "label": "Minnesota",
          "value": "Minnesota"
      },
      {
          "label": "Mississippi",
          "value": "Mississippi"
      },
      {
          "label": "Missouri",
          "value": "Missouri"
      },
      {
          "label": "Montana",
          "value": "Montana"
      },
      {
          "label": "Nebraska",
          "value": "Nebraska"
      },
      {
          "label": "Nevada",
          "value": "Nevada"
      },
      {
          "label": "New Hampshire",
          "value": "New Hampshire"
      },
      {
          "label": "New Jersey",
          "value": "New Jersey"
      },
      {
          "label": "New Mexico",
          "value": "New Mexico"
      },
      {
          "label": "New York",
          "value": "New York"
      },
      {
          "label": "North Carolina",
          "value": "North Carolina"
      },
      {
          "label": "North Dakota",
          "value": "North Dakota"
      },
      {
          "label": "Ohio",
          "value": "Ohio"
      },
      {
          "label": "Oklahoma",
          "value": "Oklahoma"
      },
      {
          "label": "Oregon",
          "value": "Oregon"
      },
      {
          "label": "Pennsylvania",
          "value": "Pennsylvania"
      },
      {
          "label": "Rhode Island",
          "value": "Rhode Island"
      },
      {
          "label": "South Carolina",
          "value": "South Carolina"
      },
      {
          "label": "South Dakota",
          "value": "South Dakota"
      },
      {
          "label": "Tennessee",
          "value": "Tennessee"
      },
      {
          "label": "Texas",
          "value": "Texas"
      },
      {
          "label": "Utah",
          "value": "Utah"
      },
      {
          "label": "Vermont",
          "value": "Vermont"
      },
      {
          "label": "Virginia",
          "value": "Virginia"
      },
      {
          "label": "Washington",
          "value": "Washington"
      },
      {
          "label": "West Virginia",
          "value": "West Virginia"
      },
      {
          "label": "Wisconsin",
          "value": "Wisconsin"
      },
      {
          "label": "Wyoming",
          "value": "Wyoming"
      }
  ]

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
      <Helmet title='Add Listing — Kalitx' />
        <Field
          name="title"
          component={TextField}
          placeholder="Title"
          size="large"
          validate={[required, maxLength100, minLength10]}
        />
        <Divider />
        <Field
            name="amount"
            component={NumberField}
            placeholder="Amount per night"
            size="large"
            validate={[required]}
            style={{ width: '30%'}}
          />
          <Field
            name="description"
            component={TextField}
            placeholder="Please enter a description"
            size="large"
            validate={[required, minLength10]}
          />
          <UploadImage setImages={this.setImages}/><br />
          <Field
            name="placeType"
            required
            component={SelectField}
            options={options}
            placeholder="Room Type"
            size="large"
            validate={[required]}
          />
        <Divider />
        <Field
            name="address"
            component={TextField}
            placeholder="Street address"
            size="large"
            validate={[required]}
          />
          <Field
              name="neighborhood"
              component={TextField}
              placeholder="Neighborhood"
              size="large"
              validate={[required]}
            />
            <Field
                name="city"
                component={TextField}
                placeholder="City"
                size="large"
                validate={[required]}
              />
          <Field
            name="state"
            component={SelectField}
            options={states}
            placeholder="State"
            size="large"
            validate={[required]}
          />
          <Field
              name="zip"
              component={TextField}
              placeholder="Zip code"
              size="large"
              validate={[required]}
            />
            <Field
              name="country"
              component={TextField}
              placeholder="Country"
              size="large"
              validate={[required]}
            />
          <Field
              name="phone"
              component={TextField}
              placeholder="Phone Number"
              size="large"
              validate={[required, phoneNumber]}
            />
        <Button type="primary" style={{ width: '100%'}} htmlType="submit">Add Listing</Button>
      </form>
    )
  }
}

let form = reduxForm({
  form: 'AddListing',
  enableReinitialize : true
})(AddListing);

form = connect(state => ({
  user: state.user,
  posts: state.posts
}), { addListing, uploadPhoto })(form);

export default withRouter(form);
