import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RequestSuccess extends Component {
  render(){
    return (
      <div>
        <p>You have successfully requested this listing</p>
        <p>This host will accept or decline.</p>
        <p>You will be charged upon acceptance and the listing will appear in your <Link to='/trips'>Trips</Link>.</p>
      </div>
    )
  }
}

export default RequestSuccess;
