import { db } from '../firebase';

export const GET_CHATS = 'get_chats';

export function getChats(user){
  return dispatch =>
    db.collection('chats').where(`users.${user}`, '==', true).get().then(snapshot => {
      const chats = {};

      snapshot.forEach((doc) => {
        chats[doc.id] = doc.data();
      })

      dispatch({
        type: GET_CHATS,
        payload: chats
      })
    })
}

export function getChat(chat){
  return dispatch =>
    db.collection('chats').doc(chat).get().then(snapshot => {
      return snapshot.data()
    })
}
