import React, { Component } from 'react';
import { Button, Input, Card } from 'antd';
import { requestReference } from '../actions/UserActions';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

class ReferenceRequest extends Component {

  state = {
    name: '',
    email: '',
    submitting: false,
    sent: false
  }

  handleNameChange = (e) => {
    const name = e.target.value;

    this.setState({ name: name })
  }

  handleEmailChange = (e) => {
    const email = e.target.value;

    this.setState({ email: email })
  }

  handleSubmit = () => {

    const { name, email } = this.state;

    this.setState({ submitting: true })
    this.props.requestReference(name, email)
    .then(res => {
      console.log(res)
      this.setState({ submitting: false, sent: true })
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {

    const { name, email, submitting, sent } = this.state;


    return (
      <Card title='Request Reference'>
        <Helmet title='Request a Reference — Kalitx' />
        {sent
          ?
            <>
              Your reference request has been sent!
            </>
          :
            <>
              <p><Input onChange={this.handleNameChange} size='large' placeholder='First and Last Name' value={name} /></p>
              <p><Input onChange={this.handleEmailChange} size='large' placeholder='Email Address' value={email} /></p>
              <p><Button onClick={this.handleSubmit} type='primary' size='large' loading={submitting}>Request Reference</Button></p>
            </>
        }
      </Card>
    );
  }

}


export default connect(state => ({
  user: state.user,
}), { requestReference })(ReferenceRequest);
