const calculateRating = (trips) => {
  let numOfRatings = Object.keys(trips).length;
  let sum = 0;

  Object.keys(trips).map((trip, key) => {
    sum = sum + trips[trip].rating;
    return sum;
  });

  return sum / numOfRatings;
}

export default calculateRating;
