import { db } from '../firebase';

export const GET_TRIPS = 'get_trips';
export const GET_PAST_TRips = 'get_past_trips';

export function fetchTrips(user){
  return dispatch =>
    db.collection('trips')
    .where('requester.uid', '==', user.uid)
    .orderBy('startDate')
    .get().then(snapshot => {
      const trips = {};

      snapshot.forEach((doc) => {
        trips[doc.id] = doc.data();
      })

      dispatch({
        type: GET_TRIPS,
        payload: trips
      })
    })
}

export function rateTrip(trip, rating, user, listing, lister, comment){

  let id = trip;
  let ratingValue = rating;

  return dispatch =>
    db.collection('trips')
    .doc(id).set({
      rating: rating,
      comment: comment
    }, { merge: true })
    .then(trip => {
      db.collection('ratings')
      .doc(id).set({
        user: user.uid,
        name: user.name,
        photoURL: user.photoURL,
        lister: lister,
        listing: listing,
        rating: ratingValue,
        comment: comment
      })
    })
}

export function fetchPastTrips(user){

  let today = new Date();

  return dispatch =>
    db.collection('trips')
    .where('requester.uid', '==', user.uid)
    .where('endDate', '<', today)
    .get().then(snapshot => {
      const trips = {};

      snapshot.forEach((doc) => {
        console.log(`end date: ${doc.data().endDate.seconds}`)
        trips[doc.id] = doc.data();
      })

      console.log(trips)
      return trips;
    })
}
