import React, {Component} from 'react';
import { db } from '../firebase.js';
import ListingCard from './ListingCard.js';

export default class UserListings extends Component {

  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      listings: []
    }
  }

  componentDidMount(){
    const ref = db.collection('listings').where('user', '==', this.props.user );

    ref.get().then(snapshot => {

      const newState = {};

      snapshot.forEach((doc) => {
        newState[doc.id] = doc.data();
      })


      this.setState({
        loaded: true,
        listings: newState
      })

    }).catch(function(error){
      console.log('Error retrieving listings', error)
    })
  }

  renderListings = () => {
    return Object.keys(this.state.listings).map((listing,key)=>{
      return (
        <ListingCard key={key} id={listing} listing={this.state.listings[listing]} />
      )
    })
  }

  render(){

    return (
      <div>
        {this.state.loaded
          ?
            this.renderListings()
          :
            'loading listings …'
        }
      </div>
    )
  }
}
