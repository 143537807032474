import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class About extends Component {
  render(){
    return (
      <div>
        <Helmet title='About — Kalitx' />
        <p>Kalitx is a home sharing and messaging service. We are based in New York and California with hosts and guests around the world. We serve an artistic community, travelers, and anyone else. We are inclusive across gender, sexual orientation and ethnicity.</p>
        <p>If you need to speak to a Kalitx Relationship Manager, you may call 24 hours a day, 7 days a week at 347-715-6046.</p>
      </div>
    )
  }
}

export default About;
