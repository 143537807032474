import React, { Component } from 'react';
import { Fields, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { addRequest } from '../actions/RequestActions';
import DatePicker from './DatePicker';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import Popup from './Popup';
import ListingPaymentInfo from './ListingPaymentInfo';
import { fetchCards } from '../actions/CardActions';
import { getListingReservations } from '../actions/ReservationActions';

class ListingForm extends Component {

  constructor(props){
    super(props)

    this.state = {
      submitting: false,
      nights: 0,
      cards: false,
      popupOpen: false
    }
  }

  componentWillMount(){
    this.props.getListingReservations(this.props.id);

    this.props.fetchCards(this.props.user.uid).then(() => {
      if(Object.keys(this.props.cards).length > 0) {
        this.setState({ cards: true })
      } else {
        this.setState({ cards: false })
      }
    })
  }

  onSubmit = (values) => {
    console.log(`start =>${values.start}, end => ${values.end}`);
    this.setState({ submitting: true })
    this.props.addRequest(this.props.listing, this.props.user, values, this.props.id).then(ref => {
      this.setState({ submitting: false })
      this.props.history.push('/request-success')
    });
  }

  modal = () => {
    this.setState({
      popupOpen: true
    })
  }

  render(){
    const { handleSubmit } = this.props;

    const renderDates = fields => (
      <DatePicker
        startDateFieldName="start"
        endDateFieldName="end"
        {...fields}
      />
    );

    const amount = this.state.nights * (this.props.listing.amount / 100);

    const setNights = (dates) => {
      const start = dates.startDate;
      const end = dates.endDate;

      try {
        const nights = end.diff(start, 'days');
        this.setState({ nights: Math.abs(nights) })
      }

      catch(err){
        console.log('error', err);
      }
    }

    const formatDates = (value, name) => {
      return moment(value);
    };
    const normalizeDates = (name, value) => {
      return value.format();
    };

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
          <Popup title="Please add payment source" visible={this.state.popupOpen}>
            Before you can make a request, please enter your credit card information in the <Link to='/payment-settings'>Payment Settings</Link> page
          </Popup>
          <strong>Choose booking dates</strong><br /><br />
          <Fields
            names={['start', 'end']}
            component={renderDates}
            normalize={normalizeDates}
            format={formatDates}
            setNights={setNights}
            reservations={this.props.reservations}
          />
          <br/><br />
          <ListingPaymentInfo amount={amount} nights={this.state.nights}/>
          <p>By clicking 'Request', you agree to pay the total amount and to these <Link to='/terms'>terms</Link></p>
          {this.state.nights > 0 && this.state.cards === false
            ?
            <Button icon='home' size="large" style={{width: '100%'}} onClick={this.modal} type="primary">Request</Button>

            :
            <Button icon='home' size="large" style={{width: '100%'}} htmlType="submit" type="primary">Request</Button>
          }
      </form>
    )
  }
}

let form = reduxForm({
  form: 'RequestListing',
  enableReinitialize : true
})(ListingForm);

form = connect(state => ({
  user: state.user,
  cards: state.cards,
  reservations: state.reservations
}), { addRequest, fetchCards, getListingReservations })(form);

export default withRouter(form);
