import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../actions/UserActions';
import { getChat } from '../actions/ChatActions';
import { getMessages, sendMessage } from '../actions/MessageActions';
import _ from 'lodash';
import { Button, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Field, reduxForm, reset } from 'redux-form';
import { TextField } from 'redux-form-antd';
import userFromChatURL from '../utils/userFromChatURL.js';

const SentMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  div {
    background: #2196F3;
    color: white;
  }
`;

const ReceivedMessage = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
`;

const TextBox = styled.div`
  border-radius: 1rem;
  background: rgba(0,0,0,0.05);
  padding: .5rem;
`;

class Message extends Component {

  constructor(props){
    super(props);

    this.state = {
      user: {}
    }
  }

  // Check which user is current
  otherUser = (object, key) => {
    const {[key]: deletedKey, ...otherKeys} = object;
    const otherUser = Object.values(otherKeys)[0];
    return otherUser;
  }

  componentWillMount(){
    const id = this.props.match.params.id;

    this.props.getMessages(id)

    this.props.getUser( userFromChatURL(id, this.props.user.uid) ).then(user => {
      this.setState({ user: user })
    })
  }

  onSubmit = (values) => {
    const id = this.props.match.params.id;

    // Send message
    this.props.sendMessage(values, this.props.match.params.id, this.props.user.uid, this.state.user).then(res => {
      this.props.dispatch(reset('SendMessage'))
      this.props.getMessages(id)
    })
  }

  renderMessages = () => {
    return _.map(this.props.messages, (message, key) => {
      if(message.user === this.props.user.uid){
        return (
          <SentMessage key={key}>
            <Avatar size='medium' src={this.props.user.photoURL} style={{ marginLeft: '.5rem' }} />
            <TextBox>{message.text}</TextBox>
          </SentMessage>
        )
      } else {
        return (
          <ReceivedMessage key={key}>
            <Link to={`/profile/${this.state.user.uid}`}><Avatar size='small' src={this.state.user.photoURL} style={{ marginRight: '.5rem' }} /></Link>
            <TextBox>{message.text}</TextBox>
          </ReceivedMessage>
        )
      }
    })
  }

  render(){

    const { handleSubmit } = this.props;

    return (
      <div>
        {this.renderMessages()}
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="message"
            component={TextField}
            size="large"
          />
          <Button type="primary" style={{ width: '100%'}} htmlType="submit">Send Message</Button>
        </form>
      </div>
    )
  }
}

let form = reduxForm({
  form: 'SendMessage',
  enableReinitialize : true
})(Message);

form = connect(state => ({
  user: state.user,
  messages: state.messages
}), { getMessages, getUser, sendMessage, getChat })(form);

export default form;
