import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import Date from './Date';
import styled from 'styled-components';

const MessageLink = styled(Link)`
  text-indent: -999em;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Item = List.Item

export default class ChatList extends Component {

  otherUser = (object, key) => {
    const {[key]: deletedKey, ...otherKeys} = object;
    const otherUser = Object.values(otherKeys)[0];
    return otherUser;
  }

  render(){

    const data = Object.values(this.props.chats);

    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        locale={{ emptyText: 'No new messages' }}
        renderItem={(item, index) => (

          <Item>
            <MessageLink to={`/messages/${Object.keys(this.props.chats)[index]}`}>View Message</MessageLink>
              <List.Item.Meta
              description={item.lastMessage}
              />
              <Date time={item.created_at} />
          </Item>
        )}
      />
    )
  }
}
