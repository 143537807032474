import React from 'react';
import { Elements } from 'react-stripe-elements';
import { StripeProvider } from 'react-stripe-elements';
import CardForm from './CardForm';

export default class Checkout extends React.Component {

  render(){
    return(
      <StripeProvider apiKey="pk_live_F3pxcq5sTNygNRzCCi1g5pLr">
          <Elements>
            <CardForm
              user={this.props.user}
              fetchCards={this.props.fetchCards}
              addCard={this.props.addCard}
            />
          </Elements>
      </StripeProvider>
    )
  }
}
