import React from 'react';
import { Helmet } from 'react-helmet';
import RegisterForm from '../components/RegisterForm';

const Register = (props) => (
  <div>
    <Helmet title='Register – Kaltix' />
    <RegisterForm />
  </div>
);

export default Register;
