import { db } from '../firebase';

export const GET_RESERVATIONS = 'get_reservations';
export const GET_LISTING_RESERVATIONS = 'get_listing_reservations';

export function fetchReservations(user){
  return dispatch =>
    db.collection('reservations').where('listingUser', '==', user.uid).get().then(snapshot => {
      const reservations = {};

      snapshot.forEach((doc) => {
        reservations[doc.id] = doc.data();
      })

      dispatch({
        type: GET_RESERVATIONS,
        payload: reservations
      })
    })
}

export function getListingReservations(id) {

  return dispatch =>
     db.collection('reservations').where('listing.id', '==', id).get().then(snapshot => {
       const reservations = {};

       snapshot.forEach((doc) => {
         reservations[doc.id] = {
           startDate: doc.data().startDate,
           endDate: doc.data().endDate,
         };
       })

       dispatch({
         type: GET_LISTING_RESERVATIONS,
         payload: reservations
       })
    })
}
