import {db, storage} from '../firebase';

export const GET_LISTINGS = 'get_listings';
export const POST_LISTING = 'post_listings';
export const UPDATE_LISTING = 'update_listing';
export const DELETE_LISTING = 'delete_listing';
export const GET_LISTING_USER = 'get_listing_user';

export function getListings() {
  const Listings = db.collection('/listings');

  return dispatch =>
    Listings.orderBy('created_at', 'desc').get().then(snapshot => {

      const listings = {};

      snapshot.forEach((doc) => {
        listings[doc.id] = doc.data();
      })

      dispatch({
        type: GET_LISTINGS,
        payload: listings
      })
    })
}

export function getListingUser(id){
  return dispatch =>
    db.collection('users').doc(id).get().then(user => {
      return user.data()
    })
}

export function uploadPhoto(file) {
  const storageRef = storage.ref();
  const number = Math.floor(Math.random()*90000) + 10000;
  const ImagesRef = storageRef.child(`images/${number}_${file.name}`);

  return dispatch =>
    ImagesRef.put(file).then((snapshot) => {
      return snapshot.ref.getDownloadURL()
    })
}

export function updateListing(values, images, id, user) {
  const Listing = db.collection('/listings').doc(id);
  return dispatch =>
    Listing.set({
      title: values.title,
      amount: values.amount * 100,
      placeType: values.placeType,
      description: values.description,
      images: images,
      address: {
        address: values.address,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone: values.phone,
        country: values.country
      },
      created_at: Date.now(),
      user: user.uid,
      userPhoto: user.photoURL,
      userName: user.name
    }, { merge: true })
}

export function getRating(id){
  return dispatch =>
    db.collection('ratings')
    .where('listing', '==', id)
    .get().then(snapshot => {
      const ratings = {};

      snapshot.forEach((doc) => {
        ratings[doc.id] = doc.data();
      })

      return ratings;
    })
}

export function addListing(values, images, user) {
  console.log(values);
  console.log(images);
  console.log(user);
  const Listing = db.collection('/listings').doc();
  return dispatch =>
    Listing.set({
      title: values.title,
      amount: values.amount * 100,
      placeType: values.placeType,
      description: values.description,
      images: images,
      address: {
        address: values.address,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone: values.phone,
        country: values.country
      },
      created_at: Date.now(),
      user: user.uid,
      userPhoto: user.photoURL,
      userName: user.name
    }, { merge: true })
}

export function deleteListing(id) {
  const Listing = db.collection('/listings').doc(id);

  return dispatch => {
    Listing.delete();
  }
}

export function requestListing(values, listing, lister, requester) {

  return dispatch =>
    db.collection('/reservations').doc().set({
      requester: requester,
      lister: lister,
      listing: listing,
      startDate: values.startDate,
      endDate: values.endDate
    })
}
