import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const ListingCard = (props) => {
  return (
    <Link to={`/listing/${props.id}`} style={{ display: 'block', marginBottom: '2rem' }}>
      <Card
        hoverable
        cover={<img alt='listing_image' src={props.listing.images[0]} />}
      >
      <Meta
        title={props.listing.title}
        description={props.listing.description}
      />
      </Card>
    </Link>
  )
}

export default ListingCard;
