import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserRequests, acceptRequest, removeRequest } from '../actions/RequestActions';
import RequestCard from '../components/RequestCard';
import { getUserCharges, chargeUser } from '../actions/ChargeActions';

class Requests extends Component {

  componentWillMount(){
    this.props.getUserRequests(this.props.user);
  }

  renderRequests = () => {
    return Object.keys(this.props.requests).map((request,key)=>{
      return (
        <RequestCard
          key={key}
          user={this.props.user}
          request={this.props.requests[request]}
          id={request}
          fetch={this.props.getUserRequests}
          accept={this.props.acceptRequest}
          charge={this.props.chargeUser}
          reject={this.props.removeRequest}
        />
      )
    })
  }

  render(){

    return (
      this.renderRequests()
    )
  }
}

export default connect(state => ({
  user: state.user,
  requests: state.requests
}), { getUserRequests, removeRequest, acceptRequest, getUserCharges, chargeUser })(Requests);
