import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchReservations } from '../actions/ReservationActions';
import ReservationCard from '../components/ReservationCard';
import PageTitle from '../components/PageTitle';
import { Helmet } from 'react-helmet';

class s extends Component {

  componentWillMount(){
    this.props.fetchReservations(this.props.user);
  }

  renderReservations = () => {
    return Object.keys(this.props.reservations).map((reservation, key)=>{
      return (
        <ReservationCard
          key={key}
          reservation={this.props.reservations[reservation]}
        />
      )
    })
  }

  render(){

    return (
      <div>
        <Helmet title='My Reservations — Kalitx' />
        <PageTitle>My Reservations with Guests</PageTitle>
        {this.renderReservations()}
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
  reservations: state.reservations
}), { fetchReservations })(s);
