import { auth, db, provider, storage, functions } from '../firebase';

export const GET_CURRENT_USER = 'get_current_user';
export const LOGOUT = 'logout';

export function getCurrentUser(){
  return dispatch =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection('users').doc(user.uid).get().then(user => {
          dispatch({
            type: GET_CURRENT_USER,
            payload: user.data()
          })
        })
      } else {
        console.log('not logged in')
      }
    });
}

export function uploadPhoto(file) {
  const storageRef = storage.ref();
  const number = Math.floor(Math.random()*90000) + 10000;
  const ImagesRef = storageRef.child(`users/${number}_${file.name}`);
  console.log(file)

  return dispatch =>
    ImagesRef.put(file)
    .then((snapshot) => {
      console.log(snapshot)
      return snapshot.ref.getDownloadURL()
    })
    .catch(err => {
      console.log(err);
    })
}

export function uploadProfilePhoto(user, file) {
  const storageRef = storage.ref();
  const number = Math.floor(Math.random()*90000) + 10000;
  const ImagesRef = storageRef.child(`users/${user}/${number}_${file.name}`);

  return dispatch => {
    return ImagesRef.put(file)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL()
    })
    .then(url => {
      return db.collection('users').doc(user).collection('photos').add({
        created_at: Date.now(),
        url: url
      })
    })
    .then(doc => {
      return db.collection('users').doc(user).collection('photos').doc(doc.id).set({
        uid: doc.id
      }, { merge: true })
    })
    .catch(err => {
      console.log(err);
    })
  }
}

export function updateUserPhoto(user, image){
  return dispatch => {
    return db.collection('users').doc(user).set({
      photoURL: image
    }, { merge: true })
  }
}

export function updateUserBio(user, bio){
  return dispatch => {
    return db.collection('users').doc(user).set({
      bio: bio
    }, { merge: true })
  }
}

export function getUser(user){
  return dispatch => {
    return db.collection('users').doc(user).get().then(user => {
      return user.data()
    })
  }
}

export function getUserPhotos(user){
  return dispatch => {
    return db.collection('users').doc(user).collection('photos').orderBy('created_at', 'desc').get().then(snapshot => {
      const photos = {};

      snapshot.forEach((doc) => {
        photos[doc.id] = doc.data();
      })

      return photos;
    })
  }
}

export function getUserRatings(user){
  console.log(user)
  return dispatch => {
    return db.collection('ratings').where('lister', '==', user).get()
    .then(snapshot => {
      const ratings = {};

      snapshot.forEach(doc => {
        ratings[doc.id] = doc.data();
      })

      console.log(ratings)
      return ratings;
    })
  }
}

export function getUserReferences(user){
  return dispatch => {
    return db.collection('users').doc(user).collection('references').get()
    .then(snapshot => {
      const references = {};

      snapshot.forEach((doc) => {
        references[doc.id] = doc.data();
      })

      return references;
    })
  }
}

export function requestReference(name, email) {
  console.log('name', name)
  console.log('email', email)
  return dispatch => {
    return functions.httpsCallable('requestReference')({ name: name, email: email })
    .then(res => {
      console.log(res)
      return res.data.text;
    })
  }
}

export function addReference(user, photo, name, text) {
  return dispatch => {
    return db.collection('users').doc(user).collection('references').add({
      name: name,
      photo: photo,
      text: text
    })
  }
}

export function deleteUserPhoto(user, photo){
  return dispatch => {
    return db.collection('users').doc(user).collection('photos').doc(photo).delete()
  }
}

export function updateBankInfo(user, values){
  return dispatch => {
    db.collection('users').doc(user).set({
      bankInfo: {
        routing: values.routing,
        account: values.account
      }
    }, { merge: true});
  }
}

export function login(){
  return dispatch => {
    return auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        const userRef = db.collection('users').doc(user.uid);

        userRef.set({
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL
        }, { merge: true});
      })
    }
}

export function createUserWithEmailAndPassword(email, password, name){
  return dispatch => {
    return auth.createUserWithEmailAndPassword(email, password)
    .then((res) => {
      const userRef = db.collection('users').doc(res.user.uid);

      userRef.set({
        uid: res.user.uid,
        name: name,
        email: email,
        photoURL: 'https://firebasestorage.googleapis.com/v0/b/kalitx-bdd78.appspot.com/o/users%2Fdefault.png?alt=media&token=b58be6f0-286c-4165-9135-e7a59431a148'
      }, { merge: true});
    })
    .catch(error => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(error)
  });
  }
}

export function signInWithEmailAndPassword(email, password){
  return dispatch => {
    auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(`${errorCode}: ${errorMessage}`)
    });
  }
}

export function logout(){
  return dispatch =>
    auth.signOut()
    .then(() => {
      dispatch({
        type: LOGOUT
      })
    })
}

export function deleteUser(user){
  return dispatch =>
  auth.signOut()
  .then(() => {
    dispatch({
      type: LOGOUT
    })
  }).then(() => {
    db.collection('users').doc(user).delete()
  })
}
