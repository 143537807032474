import React, { Component } from 'react';
import { Rate, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { fetchTrips, rateTrip } from '../actions/TripActions';

const { TextArea } = Input;

class RateTrip extends Component {

  constructor(props){
    super(props);

    this.state = {
      rating: 5,
      comment: ''
    }
  }

  handleChange = (value) => {
    this.setState({ rating: value })
  }

  handleCommentChange = (e) => {
    const comment = e.target.value;
    this.setState({ comment: comment  })
  }

  handleClick = () => {
    const trip = this.props.id;
    const rating = this.state.rating;
    const user = this.props.trip.requester;
    const listing = this.props.trip.listing.id;
    const lister = this.props.trip.listingUser;
    const comment = this.state.comment;

    this.props.rateTrip(trip, rating, user, listing, lister, comment).then(() => {
      this.props.fetchTrips(user)
    })
  }

  render(){

    return (
      <div>
        <p><Rate
          allowHalf
          defaultValue={this.state.rating}
          onChange={this.handleChange}
          style={{ color: '#1890ff' }}
        /></p>
        <p><TextArea onChange={this.handleCommentChange} /></p>
        <Button onClick={this.handleClick}>Rate</Button>
      </div>
    )
  }
}

export default connect(null, { fetchTrips, rateTrip })(RateTrip);
